import { useTranslation } from "react-i18next";
import { useDynamicResolution } from "../../hooks";
import { Main } from "../Main/Main";
import { AuthForm } from "./components/authForm/authForm";
import { RefundBody } from "./interfaces";
import { useState } from "react";
import { AuthNumberResponse, LoginUserResponse } from "../../interfaces/auth";
import { RefundForm } from "./components/RefundForm";

const initialRefundBody: RefundBody = {
  name: "",
  lastName: "",
  phone: "",
  userCode: "",
  clabe: "",
};

const Refunds = () => {
  const { t } = useTranslation();
  const isDesktop: boolean = useDynamicResolution();
  const [refundBody, setRefundBody] = useState<RefundBody>({
    ...initialRefundBody,
  });
  const [auth, setAuth] = useState<LoginUserResponse | null>(null);
  const [token, setToken] = useState<AuthNumberResponse | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleInputChange =
    (field: keyof RefundBody) =>
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      const value = typeof e === "string" ? e : e.target.value;
      const secureValue = /^[a-zA-Z0-9\s._\-@]*$/;
      if (secureValue.test(value)) {
        setRefundBody((prev) => ({
          ...prev,
          [field]: value,
        }));
      }
    };

  const handleSubmit = () => {
    setShowModal(true);
  };

  return (
    <Main isDesktop={isDesktop} t={t} hasFooter={isDesktop}>
      <section className="bg-white pt-24 px-[5%] sm:px-[3%] md:pt-40  md:pb-40">
        <RefundForm
          refundBody={refundBody}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          t={t}
        />
        <AuthForm
          refundBody={refundBody}
          handleInputChange={handleInputChange}
          auth={auth}
          setAuth={setAuth}
          setToken={setToken}
          showModal={showModal}
          setShowModal={setShowModal}
          t={t}
        />
      </section>
    </Main>
  );
};

export default Refunds;
