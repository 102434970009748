import axios from "axios";

interface ApiConsumerInterface {
  endpoint: string;
  method: "GET" | "POST" | "DELETE" | "PUT" | "PATCH";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  headers?: Record<string, string>;
}

export const apiConsumer = ({
  endpoint,
  method,
  body,
  headers,
}: ApiConsumerInterface) => {
  return axios({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    url: endpoint,
    method,
    data: body,
    headers: {
      ...headers,
      [`${process.env.REACT_APP_HEADER1}`]: "web",
      [`${process.env.REACT_APP_HEADER2}`]: "yoshi-lading",
      [`${process.env.REACT_APP_HEADER3}`]: `1`,
    },
  });
};
