import { useZxing } from "react-zxing";
import Modal from "./modal";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface BarcodeScannerProps {
  onScan: (result: string) => void;
  isOpen: boolean;
  onClose: () => void;
}

export const BarcodeScanner = ({
  onScan,
  isOpen,
  onClose,
}: BarcodeScannerProps) => {
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();

  const checkCameraPermissions = async () => {
    try {
      if (!navigator?.mediaDevices?.getUserMedia) {
        const msg = "Tu navegador no soporta el acceso a la cámara";

        setError(msg);
        return false;
      }

      await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });

      return true;
    } catch (err) {
      setError("Error al acceder a la cámara: ");
      return false;
    }
  };

  useEffect(() => {
    if (isOpen) {
      checkCameraPermissions();
    }
  }, [isOpen]);

  const { ref } = useZxing({
    onDecodeResult(result) {
      if (result) {
        onScan(result.getText());
      }
    },
    paused: !isOpen,
    onError(error) {
      setError(t("balance.errors.cameraError"));
    },
  }) as { ref: React.LegacyRef<HTMLVideoElement> };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title={t("balance.scanTitle")}>
        <div className="w-full h-full flex justify-center items-center flex-col">
          {error && (
            <div className="text-red-500 text-center mb-4">{error}</div>
          )}
          <video
            ref={ref}
            className="w-full h-full"
            playsInline
            autoPlay
            muted
          />
        </div>
      </Modal>
    </>
  );
};
