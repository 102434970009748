import { useState } from "react";

import { RefundBody } from "../../interfaces";
import { Input } from "../../../Core/Inputs";
import { loginUserService } from "../../../../services/auth";
import { isPhoneNumber } from "../../../../utils";
import { LoginUserResponse } from "../../../../interfaces/auth";
import { TFunction } from "i18next";
interface Step1Props {
  refundBody: RefundBody;
  handleInputChange: (
    field: keyof RefundBody
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  goToNextStep: () => void;
  setAuth: (auth: LoginUserResponse) => void;
  t: TFunction<"translate", undefined>;
}
export const Step1 = ({
  refundBody,
  handleInputChange,
  goToNextStep,
  setAuth,
  t,
}: Step1Props) => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (!isPhoneNumber(refundBody.phone)) {
        setError(t("refunds.modal.errors.invalidPhone"));
        setIsLoading(false);
        return;
      }
      setError("");

      const response = await loginUserService({
        phone_number: `+52${refundBody.phone}`,
      });

      if (response) {
        setAuth(response);
        goToNextStep();
      }
    } catch (error) {
      //manejar error
      setError(t("refunds.modal.errors.phoneVerificationError"));
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !isLoading && refundBody.phone.length === 10) {
      handleSubmit();
    }
  };

  return (
    <div className="w-full max-w-[80rem] mx-auto space-y-4">
      <div className="grid grid-cols-1 gap-2 items-center">
        <p className="text-gray-600 text-sm">
          {t("refunds.modal.enterPhoneNumber")}
        </p>
        <Input
          placeholder={t("refunds.modal.phone")}
          type="number"
          value={refundBody.phone}
          onChange={handleInputChange("phone")}
          onKeyPress={handleKeyPress}
          label={t("refunds.modal.phone")}
          limit={10}
          isRequired
          error={error}
          leftAddon="+52"
          focus={true}
        />
      </div>
      <div className="flex w-full ">
        <button
          disabled={isLoading || refundBody.phone.length !== 10}
          onClick={handleSubmit}
          className="bg-primary  text-white rounded-md font-bold w-full sm:w-full min-w-[120px] h-12 hover:opacity-90 disabled:opacity-50 transition-opacity flex items-center justify-center gap-2"
        >
          {isLoading ? (
            <>
              <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
              <span>{t("refunds.modal.processing")}</span>
            </>
          ) : (
            t("refunds.modal.continue")
          )}
        </button>
      </div>
    </div>
  );
};
