import { FC } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Yoshi from "../../../../../resources/logos/yoshicash.png";
import YoshiLight from "../../../../../resources/logos/yoshicash-light.png";
import { LanguageSelector } from "./LanguageSelector";
import { MobileMenuProps } from "../../../interfaces";

export const MobileMenu: FC<MobileMenuProps> = (props) => {
  const { isDarkTheme, isLanding, isMenuOpen, setIsMenuOpen, t } = props;

  return (
    <Transition
      show={isMenuOpen}
      enter="ease-out duration-300"
      enterFrom="opacity-0 transform-[scale(95%)]"
      enterTo="opacity-100 transform-[scale(100%)]"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 transform-[scale(100%)]"
      leaveTo="opacity-0 transform-[scale(95%)]"
    >
      <div
        className={`${
          isDarkTheme ? "bg-[#0F172A]" : "bg-white"
        } block w-full h-screen fixed inset-0 overflow-auto z-[1000]`}
      >
        <div className="w-full h-[87px] px-[3%] fixed flex justify-between items-center z-[1000]">
          <Link to="/" onClick={() => setIsMenuOpen(false)}>
            {isDarkTheme ? (
              <img
                src={YoshiLight}
                className="shrink-0 h-8 px-0.5"
                alt="Yoshi logo"
              />
            ) : (
              <img
                src={Yoshi}
                className="shrink-0 h-8 px-0.5"
                alt="Yoshi logo"
              />
            )}
          </Link>
          <button
            id="close-menu"
            onClick={() => setIsMenuOpen(!setIsMenuOpen)}
            aria-label="close-menu"
          >
            <XMarkIcon
              className={`h-7 w-7 ${
                isDarkTheme ? "text-white" : "text-gray-900"
              }`}
            />
          </button>
        </div>
        <div className="flex flex-col gap-10 pt-24 px-[5%] pb-6">
          <LanguageSelector isDarkTheme={isDarkTheme} isDesktop={false} />
          <div className="flex flex-col gap-6">
            <Link
              id="nav-terminals-mobile"
              className={`${
                isDarkTheme ? "text-white" : "text-gray-500 hover:text-gray-700"
              } w-fit`}
              to={isLanding ? "#terminals" : "/#terminals"}
              onClick={() => setIsMenuOpen(false)}
            >
              {t("home.terminals")}
            </Link>
            {/* <Link
              id="nav-atm-mobile"
              className={`${
                isDarkTheme ? "text-white" : "text-gray-500 hover:text-gray-700"
              } w-fit`}
              to={isLanding ? "#atm" : "/#atm"}
              onClick={() => setIsMenuOpen(false)}
            >
              {t("home.atm")}
            </Link> */}
            <Link
              id="nav-data-mobile"
              className={`${
                isDarkTheme ? "text-white" : "text-gray-500 hover:text-gray-700"
              } w-fit`}
              to={isLanding ? "#data" : "/#data"}
              onClick={() => setIsMenuOpen(false)}
            >
              {t("home.data")}
            </Link>
            <Link
              id="nav-refunds-mobile"
              className={`${
                isDarkTheme
                  ? "text-white hover:text-gray-300"
                  : "text-gray-500 hover:text-gray-700"
              } w-fit`}
              to="/refunds"
              onClick={() => setIsMenuOpen(false)}
            >
              {t("home.refunds")}
            </Link>
            <Link
              id="nav-balance-mobile"
              className="text-primary hover:text-primary/80 w-fit"
              to="/balance"
              onClick={() => setIsMenuOpen(false)}
            >
              {t("home.balance")}
            </Link>
          </div>
        </div>
      </div>
    </Transition>
  );
};
