import { useState } from "react";

import Modal from "../../../Core/modal";
import { Step1 } from "./step1";
import { RefundBody } from "../../interfaces";
import { Step2 } from "./step2";
import {
  AuthNumberResponse,
  LoginUserResponse,
} from "../../../../interfaces/auth";
import { RefundSuccess } from "./refundSuccess";
import { RefundFailed } from "./refundFailed";
import { TFunction } from "i18next";
type ComponentStatus =
  | "phone-input"
  | "code-input"
  | "refund-success"
  | "refund-error";

interface AuthFormProps {
  refundBody: RefundBody;
  handleInputChange: (
    field: keyof RefundBody
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  auth: LoginUserResponse | null;
  setAuth: (auth: LoginUserResponse) => void;
  setToken: (token: AuthNumberResponse) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  t: TFunction<"translate", undefined>;
}

export const AuthForm = ({
  refundBody,
  handleInputChange,
  auth,
  setAuth,
  setToken,
  showModal,
  setShowModal,
  t,
}: AuthFormProps) => {
  const [status, setStatus] = useState<ComponentStatus>("phone-input");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const title = {
    "phone-input": t("refunds.modal.phoneVerification"),
    "code-input": t("refunds.modal.codeVerification"),
    "refund-success": t("refunds.modal.refundSuccess"),
    "refund-error": t("refunds.modal.refundError"),
  };

  return (
    <Modal
      title={title[status]}
      isOpen={showModal}
      onClose={() => {
        setStatus("phone-input");
        setShowModal(false);
      }}
    >
      {status === "phone-input" && (
        <Step1
          refundBody={refundBody}
          handleInputChange={handleInputChange}
          goToNextStep={() => setStatus("code-input")}
          setAuth={setAuth}
          t={t}
        />
      )}
      {status === "code-input" && (
        <Step2
          refundBody={refundBody}
          auth={auth}
          setToken={setToken}
          setShowModal={setShowModal}
          goToSuccess={(message) => {
            setSuccessMessage(message);
            setStatus("refund-success");
          }}
          goToError={(message) => {
            setErrorMessage(message);
            setStatus("refund-error");
          }}
          t={t}
        />
      )}
      {status === "refund-success" && (
        <RefundSuccess
          close={() => {
            setShowModal(false);
            setStatus("phone-input");
            setSuccessMessage("");
          }}
          t={t}
          successMessage={successMessage}
        />
      )}
      {status === "refund-error" && (
        <RefundFailed
          errorMessage={errorMessage}
          close={() => {
            setShowModal(false);
            setStatus("phone-input");
          }}
          t={t}
        />
      )}
    </Modal>
  );
};
