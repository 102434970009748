import { apiConsumer } from "./api";
import { BalanceResponse, NFCBalanceResponse } from "../interfaces/balance";

export const getQrBalance = async (
  userCode: string
): Promise<BalanceResponse> => {
  const response = await apiConsumer({
    endpoint: `/tickets/${userCode}/balance`,
    method: "GET",
  });

  return response;
};

export const getNFCBalance = async (
  userCode: string
): Promise<NFCBalanceResponse> => {
  const response = await apiConsumer({
    endpoint: `/nfc-cards/${userCode}/balance`,
    method: "GET",
  });

  return response;
};
