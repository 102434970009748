import {
  AuthNumberBody,
  AuthNumberResponse,
  LoginUserBody,
  LoginUserResponse,
  UpdateUserBody,
} from "../interfaces/auth";
import { apiConsumer } from "./api";

export const loginUserService = async (
  body: LoginUserBody
): Promise<LoginUserResponse> => {
  const response = await apiConsumer({
    endpoint: "/customers/login",
    method: "POST",
    body,
  });
  return response.data;
};

export const updateUserService = async (body: UpdateUserBody, uuid: string, token: string) => {
  const response = await apiConsumer({
    endpoint: `/customers/${uuid}`,
    method: "PATCH",
    body,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const authNumberService = async (
  body: AuthNumberBody,
  token: string
): Promise<AuthNumberResponse> => {
  const response = await apiConsumer({
    endpoint: "/customers/authenticate",
    method: "POST",
    body,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
