import { FC } from "react";
import Astros from "../../../resources/logos/clients/astros.png";
import AbiertoGnp from "../../../resources/logos/clients/abierto-gnp.png";
import AuditorioExplanada from "../../../resources/logos/clients/auditorio-explanada.png";
import WtaGdl from "../../../resources/logos/clients/wta-gdl.png";
import Chivas from "../../../resources/logos/clients/chivas.png";
import Otonobierfest from "../../../resources/logos/clients/otonobierfest.png";
import WtaMerida from "../../../resources/logos/clients/wta-merida.png";
import PashMusicHall from "../../../resources/logos/clients/pash-music-hall.png";
import ChefFestivalSonora from "../../../resources/logos/clients/chef-festival-sonora.png";
import Dreamfields from "../../../resources/logos/clients/dreamfields.png";
import Calaverandia from "../../../resources/logos/clients/calaverandia.png";
import Navidalia from "../../../resources/logos/clients/navidalia.png";
import Naranjeros from "../../../resources/logos/clients/naranjeros.png";
import Soccerlife from "../../../resources/logos/clients/soccerlife.png";
import Pioneros from "../../../resources/logos/clients/pioneros.png";
import MxOpenVidanta from "../../../resources/logos/clients/mxopen-vidanta.png";
import Toluca from "../../../resources/logos/clients/toluca.png";
import UTSGdl from "../../../resources/logos/clients/uts-gdl.png";
import SanLuis from "../../../resources/logos/clients/san-luis.png";
import Venados from "../../../resources/logos/clients/venados.png";
import Juarez from "../../../resources/logos/clients/juarez.png";
import { SectionProps } from "../../../interfaces";

const logos = [
  { src: Astros, alt: "Astros logo" },
  { src: AbiertoGnp, alt: "Abierto GNP logo" },
  { src: AuditorioExplanada, alt: "Auditorio Explanada logo" },
  { src: WtaGdl, alt: "WTA GDL logo" },
  { src: Chivas, alt: "Club Chivas logo" },
  { src: Otonobierfest, alt: "Otoñobierfest logo" },
  { src: WtaMerida, alt: "WTA Merida logo" },
  { src: PashMusicHall, alt: "Pash Music Hall logo" },
  { src: ChefFestivalSonora, alt: "Festival del Chef Sonora logo" },
  { src: Dreamfields, alt: "Dreamfields logo" },
  { src: Calaverandia, alt: "Calaverandia logo" },
  { src: Navidalia, alt: "Navidalia logo" },
  { src: Naranjeros, alt: "Naranjeros logo" },
  { src: Soccerlife, alt: "Soccerlife logo" },
  { src: Pioneros, alt: "Pioneros logo" },
  { src: MxOpenVidanta, alt: "México Open Vidantaworld logo" },
  { src: Toluca, alt: "Toluca FC logo" },
  { src: UTSGdl, alt: "Ultimate Tennis Showdown GDL logo" },
  { src: SanLuis, alt: "Atlético San Luis logo" },
  { src: Venados, alt: "Venados FC logo" },
  { src: Juarez, alt: "FC Juarez logo" },
];

export const Clients: FC<SectionProps> = ({ t }) => {
  return (
    <section className="bg-[#FDFCFF] py-16 px-[5%] sm:px-[3%]">
      <div className="max-w-[80rem] mx-auto">
        <h2 className="text-2xl/relaxed lg:text-3xl/relaxed font-medium text-center">
          {t("home.theyAlreadyLiveRealCashless")}
        </h2>
        <div className="w-full flex pt-10 overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_white_128px,_white_calc(100%-128px),transparent_100%)]">
          <div className="flex [&_img]:mx-8 [&_img]:object-contain [&_img]:max-w-none animate-infinite-scroll">
            {logos.map((logo) => (
              <img key={logo.alt} src={logo.src} alt={logo.alt} />
            ))}
          </div>
          <div
            className="flex [&_img]:mx-8 [&_img]:object-contain [&_img]:max-w-none animate-infinite-scroll"
            aria-hidden="true"
          >
            {logos.map((logo) => (
              <img key={logo.alt} src={logo.src} alt={logo.alt} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
