import { InputProps } from "./interfaces";
import { useState } from "react";

export const Input = ({
  placeholder,
  type,
  value,
  onChange,
  label,
  error,
  limit = 200,
  isRequired,
  hint,
  leftAddon,
  onKeyPress,
  focus = false,
}: InputProps) => {
  const [showHint, setShowHint] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      const newValue = e.target.value;

      // Validar que no contenga caracteres no deseados ni el acento agudo
      if (!/^[0-9]*$/.test(newValue) || newValue.includes("´")) {
        return;
      }
    }

    if (type === "textNoNumbers") {
      const newValue = e.target.value;
      // Validar que no contenga números
      if (/[0-9]/.test(newValue)) {
        return;
      }
    }

    if (type === "textHexa") {
      const newValue = e.target.value;
      if (!/^[-_tick0-9A-Fa-f]*$/.test(newValue)) {
        return;
      }
    }

    if (e.currentTarget.value.length > limit) {
      return;
    }
    onChange(e);
  };

  return (
    <div className="relative w-full flex flex-col gap-2">
      <label className="text-sm font-medium text-gray-700">
        {label} {isRequired && <span className="text-primary">* </span>}
        {hint && (
          <div className="inline-block">
            <div
              onClick={() => setShowHint(true)}
              className="bg-primary text-white w-5 h-5 items-center justify-center rounded-full cursor-pointer flex"
            >
              ?
            </div>
          </div>
        )}
      </label>

      {showHint && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999] p-2">
          <div className="bg-white p-4 rounded-lg max-w-md">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-medium"></div>
              <button
                onClick={() => setShowHint(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            <div>{hint}</div>
          </div>
        </div>
      )}

      <div className="relative flex items-center">
        {leftAddon && (
          <div className="absolute left-0 pl-2 text-gray-500">{leftAddon}</div>
        )}
        <input
          placeholder={placeholder}
          autoFocus={focus}
          type="text"
          value={value}
          onChange={handleChange}
          onKeyPress={onKeyPress}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          className={`w-full shadow-sm outline-none focus:border-b focus:border-b-primary [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
            leftAddon ? "pl-10" : ""
          }`}
        />
      </div>
      <p className="text-sm text-primary h-5">{error}</p>
    </div>
  );
};
