import React, { useState, useRef, KeyboardEvent, ChangeEvent } from "react";
import { RefundBody } from "../../interfaces";
import { isPhoneNumber } from "../../../../utils";
import {
  authNumberService,
  loginUserService,
  updateUserService,
} from "../../../../services/auth";
import {
  AuthNumberResponse,
  LoginUserResponse,
} from "../../../../interfaces/auth";
import { AxiosError } from "axios";
import { refundService } from "../../../../services/refunds";
import { TFunction } from "i18next";
interface Step2Props {
  refundBody: RefundBody;
  auth: LoginUserResponse | null;
  setToken: (token: AuthNumberResponse) => void;
  setShowModal: (showModal: boolean) => void;
  goToSuccess: (message: string) => void;
  goToError: (message: string) => void;
  t: TFunction<"translate", undefined>;
}

export const Step2 = ({
  refundBody,
  auth,
  setToken,
  setShowModal,
  goToSuccess,
  goToError,
  t,
}: Step2Props) => {
  const [code, setCode] = useState<string[]>(Array(6).fill(""));
  const [error, setError] = useState<string>("");
  const [countdown, setCountdown] = useState<number>(60);
  const [isActive, setIsActive] = useState<boolean>(true);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const timerRef = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  React.useEffect(() => {
    startTimer();
    inputRefs.current[0]?.focus();
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  const startTimer = () => {
    setIsActive(true);
    setCountdown(60);
    timerRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          setIsActive(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return; // Solo permite números

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Mover al siguiente input si hay un valor
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    // Mover al input anterior al presionar backspace en un input vacío
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleRetry = async () => {
    const success = await retryCode();
    if (success) {
      startTimer();
    }
  };

  const retryCode = async () => {
    try {
      if (!isPhoneNumber(refundBody.phone)) {
        setError(t("refunds.modal.errors.invalidPhone"));
        return false;
      }

      await loginUserService({
        phone_number: `+52${refundBody.phone}`,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await authNumberService(
        {
          auth: auth?.auth || "",
          code: code.join(""),
        },
        auth?.token.token || ""
      );
      setToken(response);
      //now we update the user info
      await updateUserService(
        {
          last_name: refundBody.lastName,
          name: refundBody.name,
          phone_number: `+52${refundBody.phone}`,
        },
        auth?.token.uuid_customer || "",
        auth?.token.token || ""
      );

      submitRefund();
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.response?.data.message);
      }
      setIsLoading(false);
    }
  };

  const submitRefund = async () => {
    try {
      //now we try to get the refund
      const refundResponse = await refundService(
        {
          clabe: refundBody.clabe,
          identifier: refundBody.userCode.toUpperCase(),
        },
        auth?.token.token || ""
      );

      if (refundResponse) {
        setIsLoading(false);
        goToSuccess(refundResponse.data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data.message;
        goToError(errorMessage);
        setIsLoading(false);
      } else {
        goToError(t("refunds.modal.errors.processorError"));
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !isLoading && refundBody.phone.length === 10) {
      handleSubmit();
    }
  };

  return (
    <div className="w-full max-w-[80rem] mx-auto space-y-4">
      <p className="text-gray-600 text-sm">
        {t("refunds.modal.verificationCodeSent")}
        <strong>{`+52 ${refundBody.phone}`}</strong>
      </p>
      <div className="flex gap-2">
        {[...Array(6)].map((_, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            value={code[index]}
            ref={(el) => (inputRefs.current[index] = el)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(index, e.target.value)
            }
            onKeyPress={handleKeyPress}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className="w-12 h-12 text-center border-2 rounded-lg text-xl focus:outline-none focus:border-primary border-gray-300"
          />
        ))}
      </div>
      <div className="text-sm text-gray-600 text-center">
        {isActive ? (
          <p>
            {t("refunds.modal.didntReceiveCode")}{" "}
            {String(Math.floor(countdown / 60)).padStart(2, "0")}:
            {String(countdown % 60).padStart(2, "0")}
          </p>
        ) : (
          <button
            onClick={handleRetry}
            className="text-primary hover:underline"
          >
            {t("refunds.modal.resendCode")}
          </button>
        )}
      </div>
      <div className="flex w-full ">
        <button
          disabled={
            isLoading || code.filter((digit) => digit !== "").length !== 6
          }
          onClick={handleSubmit}
          className="bg-primary text-white rounded-md font-bold w-full sm:w-full min-w-[120px] h-12 hover:opacity-90 disabled:opacity-50 transition-opacity flex items-center justify-center gap-2"
        >
          {isLoading ? (
            <>
              <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
              <span>{t("refunds.modal.verifying")}</span>
            </>
          ) : (
            t("refunds.modal.continue")
          )}
        </button>
      </div>
      <p className="text-sm text-primary h-5">{error}</p>
    </div>
  );
};
