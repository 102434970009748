import {
  Navigate,
  Outlet,
  ScrollRestoration,
  createBrowserRouter,
} from "react-router-dom";
import Landing from "./components/Landing/Landing";
import Terms from "./components/Terms/Terms";
import Refunds from "./components/Refunds/Refunds";
import Balance from "./components/Balance/Balance";

const RootLayout = () => {
  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "/", element: <Landing /> },
      { path: "/terminos", element: <Terms /> },
      { path: "/refunds", element: <Refunds /> },
      { path: "*", element: <Navigate to="/" replace /> },
      { path: "/balance", element: <Balance /> },
    ],
  },
]);
