import { QRCodeSVG } from "qrcode.react";
import YoshiSvg from "../../../resources/logos/yoshicash.png";

export const Ticket = () => {
  return (
    <div className="flex flex-col w-40 h-60 border border-gray-300 p-2 items-center gap-2 bg-gray-100">
      <img src={YoshiSvg} alt="Yoshi" className="w-1/3" />
      <div id="qr">
        <QRCodeSVG
          value="https://yoshicash.com"
          size={64}
          level="L"
          className="bg-white p-1"
        />
      </div>
      <span className="text-[9px] text-primary">
        Tx: E2695C3A19_250141122957
      </span>
      <span className="text-[9px]">Monto cargado: $100</span>
      <span className="text-[9px]">123456789-NEGRO</span>
      <span className="text-[4px]">
        Este código contiene el saldo que ingresate en el cajero. No lo
        compartas con otras personas
      </span>
    </div>
  );
};
