import { Card } from "./card";
import { Ticket } from "./ticket";
import { useTranslation } from "react-i18next";

export const CodeHint = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-5 items-center">
      <Card />
      <span className="text-sm text-gray-600 text-center">
        {t("refunds.codeHint.cardInstructions")}
      </span>
      <Ticket />
      <span className="text-sm text-gray-600 text-center">
        {t("refunds.codeHint.ticketInstructions")}
      </span>
    </div>
  );
};
