/**
 * Validates that a CLABE (standardized bank account number) is correct.
 * @param clabe - The string containing the CLABE (must be exactly 18 digits).
 * @returns true if the CLABE is valid; false otherwise.
 */
export function validateCLABE(clabe: string): boolean {
  // The CLABE must have exactly 18 digits and contain only numbers.
  if (clabe.length !== 18 || !/^\d+$/.test(clabe)) {
    return false;
  }

  // Cyclic factors: 3, 7, 1.
  const factors = [3, 7, 1];
  let sum = 0;

  // Process the first 17 digits.
  for (let i = 0; i < 17; i++) {
    const digit = parseInt(clabe.charAt(i), 10);
    const factor = factors[i % 3];
    const product = digit * factor;
    // Use the unit digit of the product (equivalent to product % 10).
    sum += product % 10;
  }

  // Calculate the check digit using the formula:
  // (10 - (total sum mod 10)) mod 10
  const calculatedCheckDigit = (10 - (sum % 10)) % 10;
  const providedCheckDigit = parseInt(clabe.charAt(17), 10);

  return calculatedCheckDigit === providedCheckDigit;
}

/**
 `isPhoneNumber`receives a string and verify if is a phone number.
  *@param (string) string - the word you want to check
 */

export function isPhoneNumber(string: string): boolean {
  const regex = /^\d{10}$/;

  return regex.test(string);
}

/**
 * Validates that a user code has the correct length.
 * @param userCode - The user code to validate
 * @returns true if the code is valid; false otherwise
 */
export function validateUserCode(userCode: string): boolean {
  const minLength = 14;
  const maxLength = 41;

  return userCode.length >= minLength && userCode.length <= maxLength;
}
