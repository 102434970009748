import { QRCodeSVG } from "qrcode.react";

export const Card = () => {
  return (
    <div className="w-80 h-40 bg-primary flex flex-col p-4 overflow-hidden rounded-lg shadow-sm justify-end">
      <div className="flex flex-row justify-between items-end ">
        <div id="qr">
          <QRCodeSVG
            value="https://yoshicash.com"
            size={64}
            level="L"
            className="bg-white p-1"
          />
        </div>
        <div id="id" className="bg-white p-2 rounded-md ">
          <span className="underline text-red-500">0459B0F24E1290</span>
        </div>
      </div>
      <div className="footer ">
        <span className="text-[4px] text-white text-justify leading-7 text-wrap  ">
          La Tarjeta Yoshi es un medio de pago válido en el evento donde se
          adquiere. No funciona como tarjeta de crédito ni se intercambia por
          efectivo...
        </span>
      </div>
    </div>
  );
};
