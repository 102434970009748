import { RefundServiceBody, RefundServiceResponse } from "../interfaces/refund";
import { apiConsumer } from "./api";

export const refundService = async (
  body: RefundServiceBody,
  token: string
): Promise<RefundServiceResponse> => {
  const response = await apiConsumer({
    endpoint: "/customers/refund",
    method: "POST",
    body: body,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
