import React from "react";
import { TFunction } from "i18next";
interface RefundSuccessProps {
  close: () => void;
  t: TFunction<"translate", undefined>;
  successMessage: string;
}

export const RefundSuccess = ({ close, t, successMessage }: RefundSuccessProps) => {
  return (
    <div className="w-full max-w-[80rem] mx-auto space-y-4">
      <div className="flex flex-col items-center justify-center min-h-12">
        <p className="text-center">{successMessage}</p>
      </div>
      <button
        onClick={close}
        className="bg-primary text-white rounded-md font-bold w-full sm:w-full min-w-[120px] h-12 hover:opacity-90 disabled:opacity-50 transition-opacity flex items-center justify-center gap-2"
      >
        {t("refunds.modal.close")}
      </button>
    </div>
  );
};
