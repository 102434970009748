import React from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();
  return (
    <div
      className="loader w-full "
      data-content={t("balance.modal.loading")}
    ></div>
  );
};

export default Loading;
